.column.shrinking {
  flex-shrink: 1;
  width: auto !important; }

.column.growing {
  flex-grow: 1;
  width: auto !important; }

.ui.shadowed.image {
  box-shadow: 0px 2px 3px #1b1a19; }

.ui.shadowed.segment, .ui.shadowed.segments {
  box-shadow: 0px 5px 5px #15060f; }

.ui.text.container {
  font-family: kinesis-pro-3, serif; }

.ui.header .sub.header {
  color: inherit !important; }

.sidebar.menu a {
  color: white !important; }

.ui.video.wrapper.segment {
  background: none !important;
  border: none !important;
  margin-bottom: 0 !important; }

.pushable:not(body) {
  transform: none; }

.ui.image > img {
  border-radius: .28571429rem; }

figure {
  border-left: 2px solid #CCC;
  margin-left: 2em;
  margin-bottom: 1em;
  margin-right: 0rem;
  padding-left: 1em;

  > blockquote {
    margin-left: 1rem;
    margin-right: 0;
    margin-bottom: 0; }

  > figcaption {
    margin-left: 1rem; } }
